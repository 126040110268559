<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('programs')"
                        :isNewButton="checkPermission('program_store')"
                        :isColumns="true"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('programs')"
                              :isNewButton="checkPermission('program_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear" :export-excel="checkPermission('program_excelexport')" @exportExcel="downloadExcel">
                <b-row>

                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"
                            ></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.department_code"
                            ></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="4">
                        <b-form-group :label="$t('major_types')">
                            <parameter-selectbox
                                code="major_types"
                                v-model="datatable.queryParams.filter.major_type"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="4">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox
                                v-model="datatable.queryParams.filter.level"
                                code="program_levels"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="4">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="datatable.queryParams.filter.status"
                            />

<!--                            <active-or-passive  :values="statusData"/>-->
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="4">
                        <b-form-group :label="$t('code')">
                            <b-form-input v-model="datatable.queryParams.filter.code"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('faculty_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show_programs').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>

            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ActiveOrPassive from '@/components/interactive-fields/TrueFalseSelectbox.vue';
// Pages
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm";
import ShowForm from "./ShowForm";
// Services
import ProgramService from "@/services/ProgramService";

// Others
import qs from 'qs'


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        CreateForm,
        UpdateForm,
        ShowForm,
        DepartmentSelectbox,
        FacultySelectbox,
        ParameterSelectbox,
        ActiveOrPassive,
    },
    metaInfo() {
        return {
            title: this.$t('programs')
        }
    },
    data() {
        return {
            statusData:[
                {value: null, text: ''},
                {value: 'a', text: this.$t('active')},
                {value: 'p', text: this.$t('passive')}
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'program_show',
                                callback: (row) => {
                                    this.showForm(row.code);
                                },
                                show: () => {
                                    return this.checkPermission('program_update') ? false : true
                                }
                            },
                            {
                                text: this.$t('edit'),
                                permission: 'program_update',
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.updateFormShow(row.code)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                permission: 'program_delete',
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.formDelete(row.code)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('code'),
                        field: 'code',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('faculty_name'),
                        field: 'faculty_name',
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'code',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            form: {},
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                code:null,
                name:null,
            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return ProgramService.getAll(config)
                                .then((response) => {
                                    this.datatable.rows = response.data.data
                                    this.datatable.total = response.data.pagination.total

                                    this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
                                })
                                .finally(() => {
                                    this.datatable.isLoading = false;
                                });
        },

        // Clear
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        // Show
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },

        // Create
        createFormShow() {
            this.formId = null
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Update
        updateFormShow(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.updateFormModal.$refs.commonModal.show()
        },
        updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        ProgramService.del(id)
                                     .then(response => {
                                         this.$toast.success(this.$t('api.' + response.data.message));
                                         this.getRows();
                                         this.formClear();
                                     })
                                     .catch(error => {
                                         this.$toast.error(this.$t('api.' + error.data.message));
                                     });
                    }
                })
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            ProgramService.dowloandAsExcel(config)
                .then(res=>this._downloadFile(res,this.$t('programs')+'.xlsx'))
                .catch(err=>this.showErrors(err))
        }
    }
}
</script>


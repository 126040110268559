<template>
    <div>
        <b-row>
            <b-col cols="12" md="2" lg="2">
                <b-form-group :label="$t('code')">
                    <div class="label-as-input">{{ isNotNullValue(formData.code) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="5" lg="5">
                <b-form-group :label="$t('faculty')">
                    <div class="label-as-input">{{ isNotNullValue(formData.faculty_code) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="5" lg="5">
                <b-form-group :label="$t('department')">
                    <div class="label-as-input">{{ isNotNullValue(formData.department_code) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
                <b-form-group :label="$t('name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input">{{ isNotNullValue(formData.name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('short_name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input">{{ isNotNullValue(formData.short_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('diploma_name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input">{{ isNotNullValue(formData.diploma_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
                <b-form-group :label="$t('diploma_title')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input">{{ isNotNullValue(formData.diploma_title) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('level')">
                    <div class="label-as-input">{{ isNotNullValue(formData.level) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('major_type')">
                    <div class="label-as-input">{{ isNotNullValue(formData.major_type) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('mode_of_study')">
                    <div class="label-as-input">{{ isNotNullValue(formData.mode_of_study) }}</div>
                </b-form-group>
            </b-col>
<!--            <b-col cols="12" md="4" lg="3">-->
<!--                <b-form-group :label="$t('language')">-->
<!--                    <div class="label-as-input">{{ isNotNullValue(formData.language) }}</div>-->
<!--                </b-form-group>-->
<!--            </b-col>-->
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('duration')">
                    <div class="label-as-input">{{ isNotNullValue(formData.duration) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3">

                <b-form-group :label="$t('max_duration')">
                    <div class="label-as-input">{{ isNotNullValue(formData.max_duration) }}</div>
                </b-form-group>

            </b-col>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('credit_limit')">
                    <div class="label-as-input">{{ isNotNullValue(formData.credit_limit) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('student_number_format')">
                    <div class="label-as-input">{{ isNotNullValue(formData.student_number_format) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('with_thesis')">
                    <div class="label-as-input">{{ isNotNullValue(formData.with_thesis) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('with_thesis')">
                    <div class="label-as-input">{{ isNotNullValue(formData.head_of_program_name) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('status')">
                    <div class="label-as-input">{{ isNotNullValue(formData.status) }}</div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import ProgramService from '@/services/ProgramService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {},
                formLoading: false
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ProgramService.get(id)
                              .then(response => {
                                  this.formData = response.data.data;
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            }

        }
    }
</script>
